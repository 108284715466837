

.App {
  background-color: #FBFCFC;
  width: 100vw;
  height: 100vh;
  font-family: 'Inter', sans-serif;
}

h1 {
  margin-top: 0;
  color: #1C1E23;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px;
}

h3 {
  color: #1C1E23;
  font-size: 24px;
  font-weight: 400;
}

a {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 32px;
  margin-right: 32px;
  text-decoration: none;
  color: #606470;
  font-size: 16px;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
